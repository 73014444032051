const ProjectObjects = [
  {
    key: 1,
    image: require("../../assets/img/CSharpLoanCalculator.webp"),
    title: "C# Challenges",
    alt: "C# Challenges",
    sLink: "https://csharpstarterapps-production.up.railway.app/",
    gLink: "https://github.com/Composer0/CSharp.NetAndMVC",
    yLink: "https://www.youtube.com/@composer0623/videos",
    tech: [2, 7, 14, 15, 10, 31],
    info: "Used the MVC design model and applied it to my writing in C#. These projects primarily operate using 'For' loops to filter through the data to check and modify the values. Within this applciation is Palindrome Checker, FizzBuzz Finder, and my Loan Calculator.",
  },
  {
    key: 2,
    image: require("../../assets/img/beatmachine.webp"),
    title: "Beat Maker",
    alt: "Beat Maker",
    sLink: "https://www.orionpalmer.com/projects/BeatmakerProject/index.html",
    gLink: "https://github.com/Composer0/Music-Maker",
    yLink: "https://www.youtube.com/watch?v=zG6wQomYu9E",
    tech: [1, 14, 15],
    info: "Two part application that features a rhythm maker and piano. Features the use of object-oriented programming for the Beatmaker by establishing a class with multiple methods that follow if/else and switch functions. HTML data-types and audio tags are used to implement sound effects.",
  },
  {
    key: 3,
    image: require("../../assets/img/quiz-app-vue.webp"),
    title: "FERPA Quiz",
    alt: "FERPA Quiz",
    sLink: "https://ferpa-quiz-vue.netlify.app/",
    gLink: "https://github.com/Composer0/Vue-Questions-App",
    yLink: "https://youtu.be/IDGlVxWtFTg",
    tech: [4, 15, 36],
    info: "This application uses Vue's components and prop method to render a series of questions and a result based on the amount of correct answers selected.",
  },
  {
    key: 4,
    image: require("../../assets/img/tailwindLandingPage.webp"),
    title: "Manage Landing Page",
    alt: "Tailwind Landing Page",
    sLink: "https://composer0.github.io/TailwindLandingPage/",
    gLink: "https://github.com/Composer0/TailwindLandingPage",
    yLink: "https://www.youtube.com/channel/UC1PLqeZnOUcLVteRSYwk1WQ",
    tech: [14, 15, 9],
    info: "This was created as part of a Frontend Mentor challenge. During the challenge I decided to employ the use of Tailwind to better understand how it could assist me and creating mockups of websites quickly. Dark mode is enabled based on user's system preferences.",
  },
  {
    key: 5,
    image: require("../../assets/img/portfolio.webp"),
    title: "Portfolio",
    alt: "Portfolio",
    sLink: "https://www.orionpalmer.com",
    gLink: "https://github.com/Composer0/",
    yLink: "https://www.youtube.com/channel/UC1PLqeZnOUcLVteRSYwk1WQ",
    tech: [5, 1, 14, 15],
    info: "Build using React components and CSS. Navigation features the use of React Stylized Components. React-Scroll is used to create a smooth scroll effect. Maps and props are used to render project cards.",
  },
  // {
  //     key: 1,
  //     image: require("../../assets/img/Pokedex.webp"),
  //     title: "Pokedex",
  //     alt: "Pokedex",
  //     sLink: "https://www.orionpalmer.com/projects/Pokedex/index.html",
  //     gLink: "https://github.com/Composer0/Pokemon-Pokedex-API",
  //     yLink: "https://youtu.be/7d59W8mz0ZE",
  //     tech: "HTML | CSS | JavaScript | RESTful API",
  //     info: "This application features the RESTful API known as PokeAPI to gather Pokemon data by using async/await className methods upon clicking one of the regional search selectors."
  // },
  // {
  //   key: 5,
  //   image: require("../../assets/img/Keeper-App.webp"),
  //   title: "Keeper App",
  //   alt: "Keeper App",
  //   sLink: "https://glistening-raindrop-639ded.netlify.app/",
  //   gLink: "https://github.com/Composer0/Keeper-App",
  //   yLink: "https://youtu.be/Y8lChHINtFw",
  //   tech: [5, 15],
  //   info: "Uses states, props, and components to register input within the input and textarea fields. Upon clicking add, inputs are registered to a notes object and rendered onto a new note which also holds a delete feature.",
  // },

  // {
  //   key: 5,
  //   image: require("../../assets/img/simon.webp"),
  //   title: "Simon Says App",
  //   alt: "Simon Says App",
  //   sLink: "https://www.orionpalmer.com/projects/Simon-Says-Game/index.html",
  //   gLink: "https://github.com/Composer0/Simon-Game",
  //   yLink:
  //     "https://www.youtube.com/watch?v=HHl1g3QWlxc&list=PLd7QYapjN1b7A4sN2MzQE3RznKao8DvQq&index=7",
  //   tech: [1, 6, 14, 15],
  //   info: "Simon Says Game that builds incremental sequenced events while providing user with visual and audio feedback.",
  // },
  // {
  //   key: 6,
  //   image: require("../../assets/img/password-generator.webp"),
  //   title: "Password Generator",
  //   alt: "Password Generator",
  //   sLink: "https://www.orionpalmer.com/projects/PasswordGenerator/index.html",
  //   gLink: "https://github.com/Composer0/Password-Generator",
  //   yLink: "https://youtu.be/H9kpvnuPK4I",
  //   tech: [1, 14, 15],
  //   info: "Password Generator that features the toggleable use of lowercase, uppercase, numbers, and symbols to create a scalable password up to 20 characters long.",
  // },
  // {
  //   key: 7,
  //   image: require("../../assets/img/movieapp.webp"),
  //   title: "Movie Search",
  //   alt: "Movie Search",
  //   sLink: "https://www.orionpalmer.com/projects/MovieApp/index.html",
  //   gLink: "https://github.com/Composer0/Movie-App",
  //   yLink: "https://www.youtube.com/watch?v=STa79bHfTTM",
  //   tech: [1, 22, 14, 15],
  //   info: "Uses external api to generate a list of the current top movies along with their descriptions. Also includes a search feature.",
  // },
  // {
  //   key: 8,
  //   image: require("../../assets/img/photography-website.webp"),
  //   title: "Creative Website",
  //   alt: "Creative Website",
  //   sLink: "https://www.orionpalmer.com/projects/CreativeWebsite/index.html",
  //   gLink: "https://github.com/Composer0/website-template",
  //   yLink: "https://www.youtube.com/watch?v=7e0-q0aB7wg",
  //   tech: [1, 14, 15],
  //   info: "Multipage Responsive Website that features the uses library frameworks inside of JavaScript to create additional visual effects.",
  // },
  // {
  //     key: 8,
  //     image: require("../../assets/img/randomstudentpicker.webp"),
  //     title: "Whose Turn?",
  //     alt: "Whose Turn?",
  //     sLink: "https://www.orionpalmer.com/projects/RandomChoicePicker/index.html",
  //     gLink: "https://github.com/Composer0/Random-Student-Picker",
  //     yLink: "https://www.youtube.com/watch?v=u--FTVnJp0I",
  //     tech: "HTML | CSS | JavaScript",
  //     info: "Uses an input form and generates names beneath it. Random student is selected using JavaScript."
  // },
  // {
  //     key: 9,
  //     image: require("../../assets/img/ToDoApp.webp"),
  //     title: "To-Do List",
  //     alt: "To-Do List",
  //     sLink: "https://rococo-puppy-46f00d.netlify.app/",
  //     gLink: "https://github.com/Composer0/ToDoApp",
  //     yLink: "https://youtu.be/oExJQ8G2ndM",
  //     tech: "React | JavaScript | CSS",
  //     info: "Designed with react components, hooks, props, useStates. Allows users to enter and delete list items. Methods of data entry include clicking the 'Add' button and pressing 'Enter'."
  // },
  // {
  //     key: 10,
  //     image: require("../../assets/img/ThemeClock.webp"),
  //     title: "Theme Clock",
  //     alt: "Theme Clock",
  //     sLink: "https://www.orionpalmer.com/projects/ThemeClock/index.html",
  //     gLink: "https://github.com/Composer0/Theme-Clock",
  //     yLink: "https://youtu.be/w39ehbLhX4o",
  //     tech: "JavaScript | CSS | HTML",
  //     info: "A clock that uses JavaScript to modify the presentation of the website with a toggleable dark mode. JavaScript uses the Date Constructor to retrieve data to be transformed via CSS to create a functioning ticking clock."
  // },
  // {
  //     key: 11,
  //     image: require("../../assets/img/Notes.webp"),
  //     title: "Notes CRUD App",
  //     alt: "Notes CRUD App",
  //     sLink: "https://www.orionpalmer.com/projects/NotesApp/index.html",
  //     gLink: "hhttps://github.com/Composer0/Notes-App",
  //     yLink: "https://youtu.be/QLWJuKpYbNU",
  //     tech: "JavaScript | CRUD | CSS | HTML",
  //     info: "CRUD application. Uses a markdown library. Works with local storage to ensure that only the user's browser will be able to see the contents of what is written unless the content is deleted via the app or browser."
  // },
];

export default ProjectObjects;
