const languages = [
  {
    key: 1,
    img: require("../../assets/img/javascript.webp"),
    dataHover: "JavaScript",
    alt: "JavaScript",
  },
  {
    key: 2,
    img: require("../../assets/img/csharp.webp"),
    dataHover: "C#",
    alt: "C#",
  },
  {
    key: 3,
    img: require("../../assets/img/php.png"),
    dataHover: "PHP",
    alt: "PHP",
  },
];

const frameworks = [
  {
    key: 1,
    img: require("../../assets/img/vue.png"),
    dataHover: "Vue",
    alt: "Vue",
  },
  {
    key: 2,
    img: require("../../assets/img/react1.webp"),
    dataHover: "React",
    alt: "React",
  },
  {
    key: 3,
    img: require("../../assets/img/jquery.webp"),
    dataHover: "jQuery",
    alt: "jQuery",
  },
  {
    key: 4,
    img: require("../../assets/img/asp.webp"),
    dataHover: "ASP.Net",
    alt: "ASP.Net",
  },
  {
    key: 5,
    img: require("../../assets/img/blazor.webp"),
    dataHover: "Blazor",
    alt: "Blazor",
  },
  {
    key: 6,
    img: require("../../assets/img/mjml.png"),
    dataHover: "MJML",
    alt: "MJML",
  },
  {
    key: 7,
    img: require("../../assets/img/tailwind.webp"),
    dataHover: "Tailwind",
    alt: "Tailwind",
  },
  {
    key: 8,
    img: require("../../assets/img/bootstrap.webp"),
    dataHover: "BootStrap",
    alt: "BootStrap",
  },
  {
    key: 9,
    img: require("../../assets/img/sass1 (1).webp"),
    dataHover: "Sass",
    alt: "Sass",
  },
  {
    key: 10,
    img: require("../../assets/img/less.png"),
    dataHover: "Less",
    alt: "Less",
  },
  {
    key: 11,
    img: require("../../assets/img/express.webp"),
    dataHover: "Express",
    alt: "Express",
  },
];

const tools = [
  {
    key: 1,
    img: require("../../assets/img/html.webp"),
    dataHover: "HTML",
    alt: "HTML",
  },
  {
    key: 2,
    img: require("../../assets/img/css.webp"),
    dataHover: "CSS",
    alt: "CSS",
  },
  {
    key: 3,
    img: require("../../assets/img/node.webp"),
    dataHover: "Node.js",
    alt: "Node.js",
  },
  {
    key: 4,
    img: require("../../assets/img/mongodb.webp"),
    dataHover: "MongoDB",
    alt: "MongoDB",
  },
  {
    key: 5,
    img: require("../../assets/img/postgresql.webp"),
    dataHover: "PostgreSQL",
    alt: "PostgreSQL",
  },

  {
    key: 6,
    img: require("../../assets/img/azure.webp"),
    dataHover: "Azure",
    alt: "Azure",
  },
  {
    key: 7,
    img: require("../../assets/img/railway.webp"),
    dataHover: "Railway",
    alt: "Railway",
  },
  {
    key: 8,
    img: require("../../assets/img/docker.webp"),
    dataHover: "Docker",
    alt: "Docker",
  },
  {
    key: 9,
    img: require("../../assets/img/npm.webp"),
    dataHover: "npm",
    alt: "npm",
  },
  {
    key: 10,
    img: require("../../assets/img/gulp.webp"),
    dataHover: "Gulp",
    alt: "Gulp",
  },
  {
    key: 11,
    img: require("../../assets/img/vite.webp"),
    dataHover: "Vite",
    alt: "Vite",
  },
  {
    key: 12,
    img: require("../../assets/img/api.webp"),
    dataHover: "API",
    alt: "API",
  },
  {
    key: 13,
    img: require("../../assets/img/openai.webp"),
    dataHover: "OpenAI",
    alt: "OpenAI",
  },
  {
    key: 14,
    img: require("../../assets/img/postman.webp"),
    dataHover: "Postman",
    alt: "Postman",
  },
  {
    key: 15,
    img: require("../../assets/img/git.webp"),
    dataHover: "Git",
    alt: "Git",
  },
  {
    key: 16,
    img: require("../../assets/img/githubDark.png"),
    dataHover: "GitHub",
    alt: "GitHUB",
  },
  {
    key: 17,
    img: require("../../assets/img/wordpress.png"),
    dataHover: "WordPress",
    alt: "WordPress",
  },
  {
    key: 18,
    img: require("../../assets/img/stackoverflow.webp"),
    dataHover: "StackOverflow",
    alt: "StackOverflow",
  },
  {
    key: 19,
    img: require("../../assets/img/vs.webp"),
    dataHover: "Visual Studio",
    alt: "Visual Studio",
  },
  {
    key: 20,
    img: require("../../assets/img/vscode.webp"),
    dataHover: "Visual Studio Code",
    alt: "Visual Studio Code",
  },
  {
    key: 21,
    img: require("../../assets/img/acc.webp"),
    dataHover: "Adobe Creative Suite",
    alt: "Adobe Creative Suite",
  },
  {
    key: 22,
    img: require("../../assets/img/canva.webp"),
    dataHover: "Canva",
    alt: "Canva",
  },
];

const skills = [
  {
    key: 1,
    img: require("../../assets/img/javascript.webp"),
    dataHover: "JavaScript",
    alt: "JavaScript",
  },
  {
    key: 2,
    img: require("../../assets/img/csharp.webp"),
    dataHover: "C#",
    alt: "C#",
  },
  {
    key: 3,
    img: require("../../assets/img/php.png"),
    dataHover: "PHP",
    alt: "PHP",
  },
  {
    key: 4,
    img: require("../../assets/img/vue.png"),
    dataHover: "Vue",
    alt: "Vue",
  },
  {
    key: 5,
    img: require("../../assets/img/react1.webp"),
    dataHover: "React",
    alt: "React",
  },
  {
    key: 6,
    img: require("../../assets/img/jquery.webp"),
    dataHover: "jQuery",
    alt: "jQuery",
  },
  {
    key: 7,
    img: require("../../assets/img/asp.webp"),
    dataHover: "ASP.Net",
    alt: "ASP.Net",
  },
  {
    key: 8,
    img: require("../../assets/img/mjml.png"),
    dataHover: "MJML",
    alt: "MJML",
  },
  {
    key: 9,
    img: require("../../assets/img/tailwind.webp"),
    dataHover: "Tailwind",
    alt: "Tailwind",
  },
  {
    key: 10,
    img: require("../../assets/img/bootstrap.webp"),
    dataHover: "BootStrap",
    alt: "BootStrap",
  },
  {
    key: 11,
    img: require("../../assets/img/sass1 (1).webp"),
    dataHover: "Sass",
    alt: "Sass",
  },
  {
    key: 12,
    img: require("../../assets/img/less.png"),
    dataHover: "Less",
    alt: "Less",
  },
  {
    key: 13,
    img: require("../../assets/img/express.webp"),
    dataHover: "Express",
    alt: "Express",
  },
  {
    key: 14,
    img: require("../../assets/img/html.webp"),
    dataHover: "HTML",
    alt: "HTML",
  },
  {
    key: 15,
    img: require("../../assets/img/css.webp"),
    dataHover: "CSS",
    alt: "CSS",
  },
  {
    key: 16,
    img: require("../../assets/img/node.webp"),
    dataHover: "Node.js",
    alt: "Node.js",
  },

  {
    key: 17,
    img: require("../../assets/img/mongodb.webp"),
    dataHover: "MongoDB",
    alt: "MongoDB",
  },
  {
    key: 18,
    img: require("../../assets/img/postgresql.webp"),
    dataHover: "PostgreSQL",
    alt: "PostgreSQL",
  },

  {
    key: 19,
    img: require("../../assets/img/azure.webp"),
    dataHover: "Azure",
    alt: "Azure",
  },
  {
    key: 20,
    img: require("../../assets/img/npm.webp"),
    dataHover: "npm",
    alt: "npm",
  },
  {
    key: 21,
    img: require("../../assets/img/gulp.webp"),
    dataHover: "Gulp",
    alt: "Gulp",
  },
  {
    key: 22,
    img: require("../../assets/img/api.webp"),
    dataHover: "API",
    alt: "API",
  },
  {
    key: 23,
    img: require("../../assets/img/postman.webp"),
    dataHover: "Postman",
    alt: "Postman",
  },
  {
    key: 24,
    img: require("../../assets/img/git.webp"),
    dataHover: "Git",
    alt: "Git",
  },
  {
    key: 25,
    img: require("../../assets/img/githubDark.png"),
    dataHover: "GitHub",
    alt: "GitHUB",
  },
  {
    key: 26,
    img: require("../../assets/img/wordpress.png"),
    dataHover: "WordPress",
    alt: "WordPress",
  },
  {
    key: 27,
    img: require("../../assets/img/stackoverflow.webp"),
    dataHover: "StackOverflow",
    alt: "StackOverflow",
  },
  {
    key: 28,
    img: require("../../assets/img/vs.webp"),
    dataHover: "Visual Studio",
    alt: "Visual Studio",
  },
  {
    key: 29,
    img: require("../../assets/img/vscode.webp"),
    dataHover: "Visual Studio Code",
    alt: "Visual Studio Code",
  },
  {
    key: 30,
    img: require("../../assets/img/acc.webp"),
    dataHover: "Adobe Creative Suite",
    alt: "Adobe Creative Suite",
  },
  {
    key: 31,
    img: require("../../assets/img/railway.webp"),
    dataHover: "Railway",
    alt: "Railway",
  },
  {
    key: 32,
    img: require("../../assets/img/blazor.webp"),
    dataHover: "Blazor",
    alt: "Blazor",
  },
  {
    key: 33,
    img: require("../../assets/img/openai.webp"),
    dataHover: "OpenAI",
    alt: "OpenAI",
  },
  {
    key: 34,
    img: require("../../assets/img/canva.webp"),
    dataHover: "Canva",
    alt: "Canva",
  },
  {
    key: 35,
    img: require("../../assets/img/docker.webp"),
    dataHover: "Docker",
    alt: "Docker",
  },
  {
    key: 36,
    img: require("../../assets/img/vite.webp"),
    dataHover: "Vite",
    alt: "Vite",
  },
];

export { languages, frameworks, tools, skills };
